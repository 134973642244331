<template>
  <div id="userOrder" v-loading.fullscreen.lock="fullscreenLoading">
    <Dialog ref="dialog" :config="config" />
    <nav>
      <span
        @click="changestatus('all')"
        :class="form.status == 'all' ? 'active' : ''"
        class="allStatusSpan"
      >
        <span class="label">{{ $fanyi("所有订单") }}</span>
        <span class="numberCount">({{ getStatisticsData("全部") }})</span>
      </span>
      <span
        @click="changestatus('temporary')"
        :class="form.status == 'temporary' ? 'active' : ''"
      >
        <span class="label wrapTitle">{{ $fanyi("临时保存的订单") }}</span>
        <span class="numberCount"
          >({{ getStatisticsData("临时保存订单") }})</span
        >
      </span>

      <span
        @click="changestatus('offer')"
        :class="form.status == 'offer' ? 'active' : ''"
      >
        <span class="label wrapTitle">{{ $fanyi("等待报价") }}</span>
        <span class="numberCount">({{ getStatisticsData("等待报价") }})</span>
      </span>

      <span
        @click="changestatus('obligation')"
        :class="form.status == 'obligation' ? 'active' : ''"
      >
        <span class="label">{{ $fanyi("待处理的订单") }}</span>
        <span class="numberCount">({{ getStatisticsData("等待支付") }})</span>
      </span>
      <span
        @click="changestatus('confirming')"
        :class="form.status == 'confirming' ? 'active' : ''"
      >
        <span class="label">{{ $fanyi("待定") }}</span>
        <span class="numberCount">
          ({{ getStatisticsData("入金确认中") }})
        </span>
      </span>
      <span
        @click="changestatus('deal')"
        :class="form.status == 'deal' ? 'active' : ''"
      >
        <span class="label">{{ $fanyi("处理中") }}</span>
        <span class="numberCount"> ({{ getStatisticsData("处理中") }}) </span>
      </span>
      <span
        @click="changestatus('putaway')"
        :class="form.status == 'putaway' ? 'active' : ''"
      >
        <span class="label">{{ $fanyi("上架完了") }}</span>
        <span class="numberCount"> ({{ getStatisticsData("上架完了") }}) </span>
      </span>
      <span
        @click="changestatus('transport')"
        :class="form.status == 'transport' ? 'active' : ''"
      >
        <span class="label">{{ $fanyi("运输中") }}</span>
        <span class="numberCount">
          ({{ getStatisticsData("国际运输中") }})
        </span>
      </span>
      <span
        @click="changestatus('signed')"
        :class="form.status == 'signed' ? 'active' : ''"
      >
        <span class="label">{{ $fanyi("已签收2") }}</span>
        <span class="numberCount"> ({{ getStatisticsData("已签收") }}) </span>
      </span>
      <span
        @click="changestatus('cancel')"
        :class="form.status == 'cancel' ? 'active' : ''"
      >
        <span class="label">{{ $fanyi("已取消的订单") }}</span>
        <span class="numberCount">
          ({{ getStatisticsData("取消的订单") }})</span
        >
      </span>
      <div class="dip yellowIconBox">
        <div class="yellowIcon">
          ?
        </div>
      </div>
    </nav>
    <div>
      <div class="searchBox">
        <div class="myInput" style="width: 360px;">
          <i class="el-icon-search"></i>
          <input
            type="text"
            v-model="form.searchText"
            @keydown.enter="getData(form)"
            :placeholder="$fanyi('请输入关键词搜索')"
          />
        </div>
        <div class="myInput" style="width: 280px;">
          <i class="el-icon-search"></i>
          <input
            type="text"
            v-model="form.foreign_express_no"
            @keydown.enter="getData(form)"
            :placeholder="$fanyi('输入跟踪号码。')"
          />
        </div>
        <div class="myInput" style="width:345px;">
          <i class="el-icon-search"></i>
          <input
            type="text"
            v-model="form.searchGoods"
            @keydown.enter="getData(form)"
            :placeholder="$fanyi('输入ID 1688/ product link')"
          />
        </div>
        <el-date-picker
          v-model="value1"
          type="datetimerange"
          :range-separator="$fanyi('至')"
          :start-placeholder="$fanyi('开始日期')"
          :end-placeholder="$fanyi('结束日期')"
          format="dd-MM-yyyy HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          @change="setFromDate"
        >
        </el-date-picker>
        <button
          class="searchBtn"
          @click="
            form.page = 1;
            getData(form);
          "
        >
          {{ $fanyi("搜索") }}
        </button>
        <button
          class="fontBlueBtn_h"
          @click="
            form = { page: 1, pageSize: 10, currentPage: 1 };
            value1 = [];
            getData(form);
          "
        >
          {{ $fanyi("明白") }}
        </button>
      </div>
      <div class="dataBody">
        <el-table
          :data="tableData"
          border
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
          :key="oKey"
        >
          <el-table-column label="Nº" width="50">
            <template slot-scope="scope">
              <div>
                {{ (form.page - 1) * 10 + scope.$index + 1 }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            property="name"
            :label="$fanyi('订单号')"
            width="180"
          >
            <template slot-scope="scope">
              <div>
                <span
                  class="aLink"
                  @click="
                    $fun.routerToPage(
                      '/OrderDetails?type=' +
                        scope.row.status_name +
                        '&order_sn=' +
                        scope.row.order_sn,
                      true
                    )
                  "
                >
                  {{ scope.row.order_sn }}
                </span>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            v-if="form.status != 'temporary'"
            property="created_at"
            :label="$fanyi('拟议的时间')"
          >
            <template slot-scope="scope">
              <div class="timeBox">
                {{ scope.row.created_at || "————" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="!['temporary', 'obligation'].includes(form.status)"
            property="datetime_pay_submit"
            :label="$fanyi('付款日期')"
          >
            <template slot-scope="scope">
              <div class="timeBox">
                {{ scope.row.datetime_pay_submit || "————" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="false"
            property="name"
            :label="$fanyi('导出excel')"
          >
            <template>
              <div @click="getFapiao(item.order_sn)" class="exportBox">
                <img src="../../../../assets/newImg/shancg2.svg" alt="" />
              </div>
            </template>
          </el-table-column>
          <el-table-column property="name" :label="$fanyi('订单状态')">
            <template slot-scope="scope">
              <div>
                {{
                  scope.row.is_offer_error
                    ? $fanyi("事故")
                    : textConversion(scope.row.status_name)
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="!['temporary', 'offer', 'obligation'].includes(form.status)"
            property="name"
            :label="$fanyi('交货订单状态')"
            width="340"
          >
            <template slot-scope="scope">
              <div>
                <p
                  v-for="(porder_info_item, porder_info_index) in scope.row
                    .porder_info"
                  :key="porder_info_index"
                >
                  <span
                    @click="
                      $fun.routerToPage(
                        '/OrderDetails?type=' +
                          'peisong' +
                          '&order_sn=' +
                          porder_info_item.order_sn,
                        true
                      )
                    "
                    class="aLink"
                    >{{ porder_info_item.porder_sn }}</span
                  >
                  &nbsp;
                  <span>{{ $fanyi(porder_info_item.status_name) }}</span>
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="['temporary', 'obligation'].includes(form.status)"
            property="name"
            :label="$fanyi('操作')"
          >
            <template slot-scope="scope">
              <div>
                <span
                  style="cursor: pointer"
                  @click="delOrder(scope.row.order_sn)"
                  >{{ $fanyi("取消") }}</span
                >

                <button
                  v-if="form.status === 'obligation'"
                  class="searchBtn peyBtn"
                  style="margin-left: 30px"
                  @click="
                    $fun.routerToPage(
                      '/OrderDetails?type=' +
                        scope.row.status_name +
                        '&order_sn=' +
                        scope.row.order_sn,
                      true
                    )
                  "
                >
                  {{ $fanyi("前往付款") }}
                </button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="allpag">
        <span></span>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="form.currentPage"
          :page-size="10"
          :page-sizes="[10, 20, 30, 50]"
          layout=" prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCurrentMonthFirst,
  getCurrentMonthLast,
} from "../../../../utlis/date";
import Dialog from "../../../../components/public/Dialog";
import axios from "axios";
import Europess from "../../../../api/wangZhi";
let Europe = Europess + "/client/";
export default {
  data() {
    return {
      value1: null,
      form: {
        status: "all",
        page: 1,
        pageSize: 10,
        searchText: "",
        startTime: "",
        endTime: "",
      },
      fullscreenLoading: false,
      tableData: [],
      total: 0,
      tableOption: [], //表格显示哪些选项
      config: {
        top: "20vh",
        width: "500px",
        title: this.$fanyi("提示"),
        btnTxt: [this.$fanyi("确认"), this.$fanyi("取消")],
      },
      all: 0,
      ordersSumList: [], //商品列表各种类商品数据
      oKey: 2,
    };
  },
  components: { Dialog },
  computed: {},
  created() {
    this.form.status = "all";
    this.form.page = 1;
    this.form.pageSize = 10;
    // 默认显示当月
    // this.form.startTime = getCurrentMonthFirst() + " 00:00:00";
    // this.form.endTime = getCurrentMonthLast() + " 23:59:59";
    // this.value1 = [
    //   getCurrentMonthFirst() + " 00:00:00",
    //   getCurrentMonthLast() + " 23:59:59",
    // ];
    if (this.$route.query.status) {
      this.form.status = this.$route.query.status;
      this.$router.push({
        query: {},
      });
      this.getData(this.form);
    } else {
      this.getData(this.form, true);
    }
    this.ordersSum();
    (function() {
      if (window.history && window.history.pushState) {
        window.onpopstate = function() {
          window.history.pushState("forward", null, "");
          window.history.forward(1);
        };
      }
      window.history.pushState("forward", null, ""); //在IE中必须得有这两行
      window.history.forward(1);
    })();
  },
  methods: {
    // 把接口返回的状态名转换成西班牙语;
    textConversion(value) {
      let list = [
        {
          key: "temporary",
          value: this.$fanyi("预购订单"),
        },
        {
          key: "offer",
          value: this.$fanyi("报价中"),
        },
        {
          key: "obligation",
          value: this.$fanyi("等待付款"),
        },
        {
          key: "confirming",
          value: this.$fanyi("待定"),
        },
        {
          key: "deal",
          value: this.$fanyi("处理中"),
        },
        {
          key: "putaway",
          value: this.$fanyi("上架完了"),
        },
        {
          key: "purchase",
          value: this.$fanyi("正在购买"),
        },
        {
          key: "purchased",
          value: this.$fanyi("购买完成"),
        },
        {
          key: "Pending financial confirmation",
          value: this.$fanyi("等待财务确认"),
        },
        {
          key: "transport",
          value: this.$fanyi("运输中"),
        },
        {
          key: "signed",
          value: this.$fanyi("已签收2"),
        },
        {
          key: "cancel",
          value: this.$fanyi("已取消(订单状态)"),
        },
        {
          key: "delete",
          value: this.$fanyi("已删除(订单状态)"),
        },
      ];
      for (let i in list) {
        if (list[i].key == value) {
          return list[i].value;
        }
      }

      return value;
    },

    // 下载形式发票
    getFapiao(sn) {
      this.fullscreenLoading = true;
      let params = {
        order_sn: sn,
      };

      axios
        .post(Europe + "download.orderDetail", params, {
          responseType: "blob", // 1.首先设置responseType对象格式为 blob:
        })
        .then((res) => {
          this.fullscreenLoading = false;
          if (res.size < 200) {
            return this.$message.warning(this.$fanyi("操作失败"));
          }
          let blob = new Blob([res], {
            type: "application/vnd.ms-excel",
          });
          // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
          let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象

          // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
          let a = document.createElement("a");
          a.href = url;
          a.download = "order_detail_" + sn + ".xlsx";
          a.click();
          // 5.释放这个临时的对象url
          window.URL.revokeObjectURL(url);
          this.diaShow = !this.diaShow;
        })
        .catch((error) => {
          this.$message(this.$fanyi(error.message));
        });
    },

    setFromDate() {
      if (this.value1) {
        this.form.startTime = this.value1[0];
        this.form.endTime = this.value1[1];
      } else {
        this.form.startTime = "";
        this.form.endTime = "";
      }
    },

    // 获取每个订单数量
    ordersSum() {
      this.$api
        .ordersSumV2()
        .then((res) => {
          if (res.data.length == 0) return (this.ordersSumList.all = 0);
          this.ordersSumList = res.data;
        })
        .catch((err) => {});
    },

    // 获取数据，传fir代表页面打开时请求数据的场合
    getData(datas, fir) {
      this.$api
        .orderListV3(datas)
        .then((res) => {
          //预购订单有数据的时候默认展示预购订单，否则展示全部订单
          if (fir == true && res.data.data.length == 0) {
            this.form.status = "all";
            this.getData(this.form);
          }
          this.oKey++;
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.form.currentPage = res.data.currentPage;
          this.$forceUpdate();
        })
        .catch((err) => {});
    },

    // 删除订单,根据订单状态分为硬删和软删
    delOrder(order_sn) {
      this.$refs.dialog.open(
        this.$fanyi("是否取消选中的订单"),
        () => {
          let datas = {
            order_sn: order_sn,
            deleteReason: this.$fanyi("删除"),
          };
          if (this.form.status == "temporary") {
            this.$api.orderTampDelete(datas).then((res) => {
              //console.log("orderTampDelete", res);
              if (res.code != 0) return;

              this.getData(this.form);
              this.ordersSum();
            });
          } else {
            this.$api.orderRecycling(datas).then((res) => {
              //console.log("orderRecycling", res);
              if (res.code != 0) return;

              this.getData(this.form);
              this.ordersSum();
            });
          }
        },
        () => {}
      );
    },

    handleCurrentChange(val) {
      this.form.page = val;
      this.getData(this.form);
    },

    getStatisticsData(val) {
      return (
        this.$fun.findValue(this.ordersSumList, val, {
          keyName: "status_name",
          valueName: "num",
        }) || 0
      );
    },

    changestatus(in_status) {
      this.tableData = [];
      this.form.status = in_status;
      this.form.page = 1;
      this.getData(this.form);
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/css/mixin.scss";
#userOrder {
  background: #ffffff;
  border: 1px solid #e1e1e1;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;

  button {
    cursor: pointer;
  }

  .rowAndCenter {
    display: flex;
    align-items: center;
  }

  > nav {
    display: flex;
    background-color: #e8e8e8;

    > span {
      @extend .dip;
      flex-direction: column;
      max-width: 145px;
      height: 64px;
      padding: 13px;
      background: #e8e8e8;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #000000;
      line-height: 16px;
      cursor: pointer;
      position: relative;
      flex: 1;

      &::after {
        content: " ";
        width: 1px;
        height: 36px;
        background: #cccccc;
        position: absolute;
        right: 0;
      }
      &.active {
        background-color: #ffffff;
        border-bottom: none;
      }
      &.active::after {
        background: #ffffff;
      }
      .label {
        line-height: 18px;
        white-space: nowrap;
        display: block;
      }
      span.wrapTitle {
        white-space: normal;
        line-height: 1.1;
      }

      .numberCount {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #1e2997;
      }
    }
    > span.allStatusSpan {
      flex: 0 0 60px;
    }
    .yellowIconBox {
      flex: 0 0 40px;
      .yellowIcon {
        @extend .dip;
        width: 24px;
        height: 24px;
        background: #fbbb21;
        border-radius: 50%;
        color: #fff;
      }
    }
  }

  > div {
    // min-height: 720px;

    border-top: none;
    padding: 20px 20px 37px 20px;

    .searchBox {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      gap: 12px;
      .myInput {
        height: 40px;

        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;

        > i {
          width: 20px;
          height: 24px;
          margin: 9px 11px 9px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
        }

        > input {
          border: none;
          border-radius: 4px;
          border: 1px solid #c0c4cc;
          width: 100%;
          height: 100%;
          font-size: 14px;
          padding-left: 40px;
          padding-right: 10px;
          transition: 0.3s;

          &:focus {
            border-color: #1e2997;
          }
        }
      }

      /deep/.el-date-editor {
        height: 40px;
        margin-right: 20px;
        border-radius: 4px;

        .el-range-input {
          font-size: 14px;
        }

        .el-range-separator {
          width: 50px;
        }
      }
    }

    .dataBody {
      margin-bottom: 40px;

      /deep/thead {
        .el-table__cell {
          width: 50px;
          height: 48px;
          background: #f0f0f0;
          padding: 5px 0;
          .cell {
            font-weight: 400;
            word-break: keep-all;
            font-size: 12px;
            line-height: 1.3;
          }
        }
      }

      /deep/tbody {
        .el-table__cell {
          .cell {
            @extend .dip;
            min-height: 36px;
            font-weight: 400;
            word-break: keep-all;
            font-size: 12px;
            line-height: 1.5;
            color: #000000;
          }
        }
      }

      .timeBox {
        word-break: keep-all;
      }

      .exportBox {
        img {
          width: 20px;
          cursor: pointer;
        }
      }
    }

    .allpag {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 25px 0;

      > a {
        display: inline-block;
        border: 1px solid #dcdfe6;
        height: 28px;
        background-color: white;
        text-align: center;
        padding: 0 10px;
        font-size: 13px;
        line-height: 28px;
        border-radius: 2px;
      }

      /* 分页被选中的页码样式 */
      /deep/ .el-pager li {
        margin: 0 3px;
        border-radius: 4px;
        box-shadow: 0px 0px 6px 0px rgba(61, 61, 61, 0.1);

        &:hover {
          color: $homePageSubjectColor;
        }

        &.active {
          background-color: $homePageSubjectColor;
          color: white;
        }
      }
    }
  }
}

.searchBtn {
  width: 90px;
  height: 40px;
  background: $homePageSubjectColor;
  border-radius: 4px;

  color: #ffffff;
  font-size: 16px;
}

.searchBtn.peyBtn {
  width: 100px !important;
}
.fontBlueBtn_h {
  padding: 0 20px;
  font-size: 16px;
  font-weight: 400;
}

.aLink {
  color: #1e2997;
  text-decoration: none;
}
</style>
